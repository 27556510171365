import React from 'react';
import Helmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import BrandTwo from "../elements/BrandTwo";




const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--33',
        category: 'Desarrollamos proyectos de bienes raíces en Guatemala.',
        title: 'PROMOPESA, S.A.',
        description: '',
        buttonText: '',
        buttonLink: ''
    },
   
]

const InteractiveAgency = () => {
    return (
        <>
            <Helmet pageTitle="Promopesa" />
            <Header />
            
            {/* Start Slider Area   */}
            <div className="slider-wrapper">
                <div className="slider-activation">
                    {SlideList.map((value , index) => (
                        <div className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="8">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start About Area  */}
            <div className="rn-about-area ptb--120 bg_color--1">
                <div className="rn-about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">¿Quién es Promopesa?</h2>
                                        <p className="description">
                                        Somos una empresa de bienes raíces en Guatemala,  nos especializamos en la venta, alquiler y desarrollo de propiedades comerciales, terrenos y proyectos inmobiliarios. Hemos establecido colaboraciones estratégicas con reconocidas empresas de la región, como AKI, Farmacia Batres, Fotolab, Gallo Más Gallo, iStore,  entre otros líderes del sector.
                                        </p>
                                        <p className="description">
                                        Con una trayectoria de más de dos décadas, nos hemos destacado por nuestra experiencia, profesionalismo y compromiso en el sector inmobiliario. Nuestro principal objetivo es brindar soluciones integrales a nuestros clientes, tanto nacionales como internacionales, y ayudarles a encontrar las mejores oportunidades de inversión en el mercado guatemalteco.
                                        </p>
                                        <p className="description">
                                        Contamos con un amplio catálogo de propiedades que incluyen locales comerciales en ubicaciones estratégicas, así como terrenos para desarrollos. Nuestro equipo se encarga de realizar análisis del mercado y ofrecer asesoramiento personalizado para asegurar que nuestros clientes tomen decisiones informadas y rentables.
                                        </p>
                                        <p className="description">
                                        En resumen, somos una empresa líder en el mercado inmobiliario guatemalteco, con una amplia gama de propiedades y desarrollos, y una red de colaboración con grandes empresas regionales. Nuestro compromiso es proporcionar a nuestros clientes soluciones inmobiliarias confiables y rentables, respaldadas por nuestro conocimiento y experiencia en el sector.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Area  */}


            {/* Start Video Area  */}
            <div className="rn-section pb--120 bg_color--1">
                <div className="container">
                        <div className="row sercice-details-content align-items-center">
                        <div className="col-lg-12">
                            <div className="thumb position-relative">
                                <img className="w-100" src="/assets/images/bg/bg-image-26.jpg" alt="Ubicaciones"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Video Area  */}

            {/* Start Brand Area  */}
            <div className="rn-brand-area pb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <BrandTwo />
                        </div>
                    </div>
                </div>
            </div>
            {/* End Brand Area  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <FooterTwo />
        </>
    )
}

export default InteractiveAgency
